import React from 'react';
import { IdentityContextProvider } from 'react-netlify-identity-widget';

export const wrapRootElement = ({ element }) => {
  return (
    <IdentityContextProvider url={`https://rohs-journal-dev.netlify.com/`}>
      {element}
    </IdentityContextProvider>
  );
};
